<template>
    <v-card class="white border-boxGlobal" style="padding: 1rem; min-height: 60dvh;">
        <block v-if="loading" />

        <v-row class="ml-1 mr-1">
            <v-col class="tabsNH" cols="12">
                <v-col cols="12" style="display: flex; justify-content: space-between; min-height: 50px">
                    <div>
                        <v-btn small class="mx-2 btndelete" v-if="selectedRequest" @click.prevent="handleDeleteRequest" style="align-self: flex-start;">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                    <div>
                        <v-btn class="mx-2 btn-add" style="height: 28px;" v-if="dataToAdjust.length" @click.prevent="saveAdjustmentRequest(1)">Enviar solicitud</v-btn>
                    </div>
                    <div>
                        <v-btn small class="mx-2 btn-add" @click.prevent="showRequests = true">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn small class="mx-2 btnclean" @click="handleReset">
                            <v-icon>mdi-broom</v-icon>
                        </v-btn>
                        <v-btn small class="mx-2 btnsave" v-if="dataToAdjust.length" @click.prevent="saveAdjustmentRequest(null)">
                            <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-col>
        </v-row>

        <v-card-title class="mt-2 mb-2"> Solicitud de ajuste de inventario </v-card-title>

        <v-row style="padding: 0 2rem; justify-content: flex-end;">
            <v-col cols="12" md="4">
                <date-picker label="Fecha solicitud de ajuste" @dateSelected="handleAdjustmentDate" v-model="adjustmentDate"
                    :value="adjustmentDate"/> 
            </v-col>
        </v-row>
    
        <v-card-actions class="mt-3" style="align-items: flex-start;">
            <div style="display: flex; flex-direction: row; gap: 2rem;">
                <CustomAutoComplete
                    v-if="false"
                    v-model="entryKey"
                    :filter-endpoint="$API.inventoryReports.getInventorySearchReport"
                    :search="{ ...search }"
                    :items="inventoryDataList"
                    :item-text="'ItemName'"
                    :isProd="true"
                    @select="(e) => handleSelectorItem(e)"
                    :item-value="'ID_Item'"
                    label="Buscar productos"
                    :emitComplete="true"
                    @updateItems="filterListHandler"
                    style="width: 250px;"
                    :disabled="selectedRequest !== null"
                >
                </CustomAutoComplete>
                <v-btn @click="showDataToAdjust = true" class="btn-add" style="height: 30px;" :disabled="selectedRequest !== null">Seleccionar items</v-btn>
            </div>
            <v-spacer></v-spacer>
            <!-- <v-btn @click="handleReset" class="btnclean" style="height: 30px;">Limpiar lista</v-btn> -->
        </v-card-actions>

        <DxDataGrid 
            v-if="!selectedRequest"
            class="isScrolledByGeneralFilter" 
            :data-source="dataToAdjust" 
            :show-borders="true"
            @exporting="onExporting"  
            :scrolling="{ useNative: true }"
            :column-auto-width="true"
        >
            <DxExport :enabled="true" />
            <DxEditing :allow-updating="true" mode="cell" />
            <DxColumn cell-template="deleteItem" caption="" :width="80"/>
            <DxColumn data-field="ID_Item" caption="ID Producto" :allow-editing="false" />
            <DxColumn data-field="PrincipalBarCode" caption="Código Alterno Principal" :allow-editing="false" />
            <DxColumn data-field="ItemName" caption="Nombre Producto" :allow-editing="false" />
            <DxColumn data-field="Lote" caption="Lote" :allow-editing="false" />
            <!-- <DxColumn v-if="$store.getters['rolesUser/getArrayCustomRole']([242])" data-field="LastPurchaseDate" caption="Última Fecha de compra" />
            <DxColumn v-if="$store.getters['rolesUser/getArrayCustomRole']([242])" data-field="LastPurchasePrice" caption="Último Costo de compra" />
            <DxColumn v-if="$store.getters['rolesUser/getArrayCustomRole']([242])" data-field="AverageCostFormatted" caption="Costo promedio" /> -->
            <!-- <DxColumn data-field="Providers" caption="Proveedores" :allow-editing="false" /> -->
            <DxColumn data-field="WarehouseName" caption="Almacén" :allow-editing="false" />
            <DxColumn data-field="selectLocation" cell-template="selectLocation" caption="Ajustar Ubicación" :allow-editing="false"/>
            <DxColumn data-field="location" cell-template="location" caption="Seleccionar Ubicación" :allow-editing="false" :width="150" />
            <DxColumn data-field="familyName" caption="Familia" :allow-editing="false" />
            <DxColumn data-field="categoryName" caption="Categoria" :allow-editing="false" />
            <DxColumn data-field="subcategoryName" caption="Subcategoria" :allow-editing="false" />
            <!-- <DxColumn data-field="ItemMeasureUnit" caption="Unidad" /> -->
            <!-- <DxColumn data-field="ItemMinimum" caption="Minimo" /> -->
            <!-- <DxColumn data-field="ItemMaximum" caption="Maximo" /> -->
            <DxColumn data-field="TotalStock" :width="100" caption="Existencias" :allow-editing="false" />
            <DxColumn data-field="StockAdjustment" :width="120" caption="Existencias fisicas" :allow-editing="true" data-type="number" />
            <template #deleteItem="{ data }">
                <!-- <v-checkbox :value="data.data.checked" @change="data.data.checked = $event" style="height: 20px; width: 20px; padding: 0; margin-inline: auto;"></v-checkbox> -->
                <a v-if="!data.data.isSaved" href="#" @click.prevent.stop="handleDeleteAdjustmentData(data.data)">
                    <v-icon>mdi-delete</v-icon>
                </a>
            </template>
            <template #selectLocation="{ data }">
                <v-checkbox v-model="data.data.selectLocation" @change="(value) => onSelectLocationChange(value, data)" style="height: 20px; width: 20px; padding: 0; margin-inline: auto;"></v-checkbox>
            </template>
            <template #location="{ data }">
                <span v-if="!data.data.selectLocation">{{ data.data.location }}</span>
                <v-autocomplete
                    v-else
                    v-model="data.data.location_id"
                    :items="data.data.locations || []"
                    item-text="location"
                    item-value="ID_location"    
                    :disabled="!data.data.selectLocation"
                    @change="(value) => {
                        const selectedItem = data.data.locations.find(item => item.ID_location === value);
                        handleSelectLocation(selectedItem ? selectedItem.ID_location : null, selectedItem ? selectedItem.location : null, data.data);
                    }"
                    style="height: 20px; padding: 0; margin-inline: auto; margin: -5px 0px 5px;"
                ></v-autocomplete>
                <!-- <v-autocomplete
                    v-else
                    v-model="data.data.location_id"
                    :items="data.data.locations || []"
                    item-text="location"
                    item-value="ID_location"
                    :disabled="!data.data.selectLocation"
                    style="height: 20px; padding: 0; margin-inline: auto; margin: -5px 0px 5px;"
                    @change="(value) => handleLocationChange(value, data.data)" 
                    ></v-autocomplete> -->
                   
            </template>
            <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />
            <DxPaging :page-size="10" />
        </DxDataGrid>
        <DxDataGrid
            v-else
            class="isScrolledByGeneralFilter" 
            :data-source="dataToAdjust" 
            :show-borders="true"
            @exporting="onExporting"  
            :scrolling="{ useNative: true }"
            :column-auto-width="true"
        >
            <DxExport :enabled="true" />
            <DxEditing :allow-updating="true" mode="cell" />
            <DxColumn cell-template="deleteItem" caption="" :width="80"/>
            <DxColumn data-field="ID_Item" caption="ID Producto" :allow-editing="false" />
            <DxColumn data-field="PrincipalBarCode" caption="Código Alterno Principal" :allow-editing="false" />
            <DxColumn data-field="ItemName" caption="Nombre Producto" :allow-editing="false" />
            <DxColumn data-field="ID_ItemBatch" caption="Lote" :allow-editing="false" />
            <!-- <DxColumn v-if="$store.getters['rolesUser/getArrayCustomRole']([242])" data-field="LastPurchaseDate" caption="Última Fecha de compra" />
            <DxColumn v-if="$store.getters['rolesUser/getArrayCustomRole']([242])" data-field="LastPurchasePrice" caption="Último Costo de compra" />
            <DxColumn v-if="$store.getters['rolesUser/getArrayCustomRole']([242])" data-field="AverageCostFormatted" caption="Costo promedio" /> -->
            <!-- <DxColumn data-field="Providers" caption="Proveedores" :allow-editing="false" /> -->
            <DxColumn data-field="Warehouse" caption="Almacén" :allow-editing="false" />
            <DxColumn data-field="selectLocation" cell-template="selectLocation" caption="Ajustar Ubicación" :allow-editing="false"/>
            <DxColumn data-field="location" cell-template="location" caption="Seleccionar Ubicación" :allow-editing="false" :width="150" />
            <DxColumn data-field="Family" caption="Familia" :allow-editing="false" />
            <DxColumn data-field="Category" caption="Categoria" :allow-editing="false" />
            <DxColumn data-field="SubCategory" caption="Subcategoria" :allow-editing="false" />
            <!-- <DxColumn data-field="ItemMeasureUnit" caption="Unidad" /> -->
            <!-- <DxColumn data-field="ItemMinimum" caption="Minimo" /> -->
            <!-- <DxColumn data-field="ItemMaximum" caption="Maximo" /> -->
            <DxColumn data-field="TotalStock" :width="100" caption="Existencias" :allow-editing="false" />
            <DxColumn data-field="StockAdjustment" :width="120" caption="Existencias fisicas" :allow-editing="true" data-type="number" />
            <template #deleteItem="{ data }">
                <!-- <v-checkbox :value="data.data.checked" @change="data.data.checked = $event" style="height: 20px; width: 20px; padding: 0; margin-inline: auto;"></v-checkbox> -->
                <a v-if="!data.data.isSaved" href="#" @click.prevent.stop="handleDeleteAdjustmentData(data.data)">
                    <v-icon>mdi-delete</v-icon>
                </a>
            </template>
            <template #selectLocation="{ data }">
                <v-checkbox v-model="data.data.selectLocation" @change="(value) => onSelectLocationChange(value, data)" style="height: 20px; width: 20px; padding: 0; margin-inline: auto;"></v-checkbox>
            </template>
            <template #location="{ data }">
                <span v-if="!data.data.selectLocation">{{ data.data.location }}</span>
                <v-autocomplete
                    v-else
                    v-model="data.data.location_id"
                    :items="data.data.locations || []"
                    item-text="location"
                    item-value="ID_location"    
                    :disabled="!data.data.selectLocation"
                    @change="(value) => {
                        const selectedItem = data.data.locations.find(item => item.ID_location === value);
                        handleSelectLocation(selectedItem ? selectedItem.ID_location : null, selectedItem ? selectedItem.location : null, data.data);
                    }"
                    style="height: 20px; padding: 0; margin-inline: auto; margin: -5px 0px 5px;"
                ></v-autocomplete>
                <!-- <v-autocomplete
                    v-else
                    v-model="data.data.location_id"
                    :items="data.data.locations || []"
                    item-text="location"
                    item-value="ID_location"
                    :disabled="!data.data.selectLocation"
                    style="height: 20px; padding: 0; margin-inline: auto; margin: -5px 0px 5px;"
                    @change="(value) => handleLocationChange(value, data.data)" 
                    ></v-autocomplete> -->
                   
            </template>
            <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />
            <DxPaging :page-size="10" />
        </DxDataGrid>

        <!-- <v-card-actions class="mt-5 mb-5">
            <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                        <v-btn class="btn-add" @click.prevent="saveAdjustmentRequest" :disabled="!adjustmentDate">Guardar solicitud</v-btn>
                    </v-col>
            </v-row>
        </v-card-actions> -->

        <v-dialog v-model="showDataToAdjust" max-width="80%" scrollable>
            <v-card class="white border-boxGlobal" style="overflow-y: scroll; padding: 1rem;">
                <v-card-actions class="mt-3">
                    <v-btn v-if="selectAll" @click.prevent="handleSelectAll(false)" class="btn-add">Deseleccionar todos</v-btn>
                    <v-btn v-else @click.prevent="handleSelectAll(true)" class="btn-add">Seleccionar todos</v-btn>
                </v-card-actions>
                <VersatileFilter 
                    :columnsSearch="columsConfig" 
                    :search="search" 
                    @emptyFilter="getEarningDetail" 
                    @filtered="filterHandler" 
                    :key="Reloadfilter" 
                    :useCache="true"
                    @updateCache="updateCache" 
                    class="mx-3"
                    :oneDateFilter="true"
                    :columnfilterEndpoint="$API.inventoryReports.getInventoryFilterReportColumn"
                >
                    <DxDataGrid 
                        class="isScrolledByGeneralFilter" 
                        :data-source="inventoryData" 
                        :show-borders="true"
                        :scrolling="{ useNative: true }"
                        :column-auto-width="true"
                    >
                        <DxExport :enabled="false" />
                        <DxColumn cell-template="selectToAdjust" caption="Seleccionar" :width="80"/>
                        <DxColumn data-field="ID_Item" caption="ID Producto" />
                        <DxColumn data-field="PrincipalBarCode" caption="Código Alterno Principal" />
                        <DxColumn data-field="ItemName" caption="Nombre Producto" />
                        <DxColumn data-field="Lote" caption="Lote" />
                        <DxColumn data-field="Providers" caption="Proveedores" />
                        <DxColumn data-field="familyName" caption="Familia" />
                        <DxColumn data-field="categoryName" caption="Categoria" />
                        <DxColumn data-field="subcategoryName" caption="Subcategoria" />
                        <DxColumn data-field="WarehouseName" caption="Almacén" />
                        <DxColumn data-field="Warehouse" caption="ID Almacén" :allow-editing="false" />
                        <DxColumn data-field="location" caption="Ubicación" />
                        <DxColumn data-field="location_id" caption="ID Ubicación" />
                        <!-- <DxColumn data-field="ItemMaximum" caption="Maximo" /> -->
                        <DxColumn data-field="TotalStock" :width="100" caption="Disponible" />
                        <template #selectToAdjust="{ data }">
                            <v-checkbox v-model="data.data.checked" @change="data.data.checked = $event" style="height: 20px; width: 20px; padding: 0; margin-inline: auto;"></v-checkbox>
                        </template>
                        <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />
                        <DxPaging :page-size="10" />
                    </DxDataGrid>
                </VersatileFilter>
                <v-card-actions class="mt-3">
                    <v-btn @click="showDataToAdjust = false" class="btndelete">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="btnsave" @click="addItemsToRequestAdjustment">Agregar items</v-btn>
                </v-card-actions>
            </v-card>
            <!-- <v-card class="border" style="max-height: inherit; overflow-y: auto; padding: 1rem;">
                <v-btn @click="showDataToAdjust= false" elevation="0" color="red" class="close-btn align-self-end">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <div v-if="selectedItem" style="padding: 1rem;">
                    <v-row style="margin-bottom: 1rem;">
                        <v-col cols="12" md="12">
                            <h3>Detalle de Producto</h3>
                        </v-col>
                    </v-row>
                    <v-row style="margin-bottom: 1rem;">
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>ID Producto: </strong>
                                {{ selectedItem.ID_Item }}
                            </span>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>Nombre: </strong>
                                {{ selectedItem.ItemName }}
                            </span>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>Almacén: </strong>
                                {{ selectedItemAdjWarehouseName }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row style="margin-bottom: 1rem;">
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>Unidad: </strong>
                                {{ selectedItem.ItemMeasureUnit }}
                            </span>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>Lote: </strong>
                                {{ selectedItem.Lote ? selectedItem.Lote : "Por defecto" }}
                            </span>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>Último precio ingresado: </strong>
                                {{ selectedItem.LastPrice }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row style="margin-bottom: 1rem;">
                        <v-col cols="12" md="12">
                            <h3>Parametros de ajuste</h3>
                        </v-col>
                    </v-row>
                    <v-row style="margin-bottom: 1rem;">
                        <v-col cols="12" md="4">
                            <v-radio-group  v-model="adjustmentForm.add" row>
                                <v-radio label="Agregar existencia" :value="true"></v-radio>
                                <v-radio label="Restar existencia" :value="false"></v-radio>                           
                            </v-radio-group>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <v-text-field v-model="adjustmentForm.quantity" label="Cantidad" outlined dense type="number" />
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <v-text-field v-model="getSetAverageCostAdjust" label="Costo unitario de Compra"  outlined dense />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col col="12" md="4" xl="4">
                            <v-text-field v-model="getSetLoteAdjust" label="Lote" :value="this.selectedItem.Lote"  outlined dense />
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <v-select v-model="getSetWarehouseAdjust" label="Almacén"
                                :items="datafilter.Warehouses" item-text="name" item-value="value"  attach
                            outlined dense />
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <v-btn @click="showComments = !showComments" :class="adjustmentForm.comment ? 'btnclean' : 'btnblack'">
                                Comentarios
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <h2>
                                {{ "Existencias Actuales: " + stockItemAdjToShow }}
                            </h2>
                        </v-col>
                        <v-col cols="12" md="6">
                            <h2>
                                {{ "Existencias Nuevas: " + totalStockItemAdjToShow }}
                            </h2>
                        </v-col>
                    </v-row>
                </div>
                <v-card-actions class="mt-3">
                    <v-btn @click="showDataToAdjust = false" class="btndelete">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="btnsave" @click="addItemsToRequestAdjustment">Agregar items</v-btn>
                </v-card-actions>
            </v-card> -->
        </v-dialog>

        <v-dialog v-model="showRequests" max-width="80%" scrollable>
            <v-card class="white border-boxGlobal" style="padding: 1rem; overflow: auto;">
                <GeneralFilter 
                    :filterEndpoint="$API.inventoryReports.getFilteredAdjustmentRequest"
                    :search="search" 
                    @emptyFilter="getAdjustmentRequest" 
                    @filtered="filterRequestsHandler"
                >
                    <DxDataGrid 
                        class="isScrolledByGeneralFilter" 
                        :data-source="adjustmentRequests" 
                        :show-borders="true"
                        :scrolling="{ useNative: true }"
                        :column-auto-width="true"
                        key-expr="ID"
                    >
                        <DxExport :enabled="false" />
                        <DxColumn cell-template="selectToCheck" caption="" :width="80"/>
                        <DxColumn data-field="ID_Request" caption="ID Solicitud" />
                        <DxColumn data-field="RequestDate" caption="Fecha de solicitud" />
                        <DxColumn data-field="AdjustmentDate" caption="Fecha de verificación" />
                        <DxColumn cell-template="StatusRequest" caption="Estado" />
                        <DxColumn data-field="ID_User_Request" caption="Usuario solicitante" />
                        <DxColumn data-field="ID_User_Adjust" caption="Usuario verificador" />
                        <template #selectToCheck="{ data }">
                            <a href="#" @click.prevent.stop="handleSelectRequest(data.data)">
                                <v-icon>mdi-eye</v-icon>
                            </a>
                        </template>
                        <template #StatusRequest="{ data }">
                            <p>{{ statusParams[data.data.Status] }}</p>
                        </template>
                        <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />
                        <DxPaging :page-size="10" />
                    </DxDataGrid>
                </GeneralFilter>
            </v-card>
        </v-dialog>

        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>
    </v-card>
</template>

<script>
import { DxDataGrid, DxColumn, DxExport, DxPager, DxPaging, DxEditing } from "devextreme-vue/data-grid";
import { printToExcel } from "@/helpers/printToexcel";
import VersatileFilter from "@/components/VersatileFilter";
import CustomAutoComplete from "@/components/CustomAutoComplete.vue";
import GeneralFilter from  "@/components/GeneralFilter";
import Block from "@/components/Block";
import DatePicker from "@/components/DatePicker";
import Alerts from "@/components/Alerts";

export default {
    name: "InventoryAdjustmentRequest",
    components: {
        Block,
        DxDataGrid,
        DxColumn,
        DxPager,
        DxExport,
        DxPaging,
        DxEditing,
        DatePicker,
        Alerts,
        VersatileFilter,
        GeneralFilter,
        CustomAutoComplete
    },
    props: ["win", "datafilter"],
    data() {
        return {
            previousLocationId: null,
            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
            loading: false,
            panel: 0,
            inventoryData: [],
            inventoryDataList: [],
            adjustmentRequests: [],
            selectedRequest: null,
            start: null,
            end: null,
            summary: {
                totalUnitSales: 0,
                totalCostSales: 0,
            },
            search: {
                status: 0,
                eq: true
            },
            showFilter: false,
            filterParams: {
                ID_Item: null,
                ItemName: null,
                ItemLote: null,
                providersNames: null,
                WarehouseName: null,
                add: null,
            },
            Reloadfilter: 0,
            columsConfig: [
                {
                    name: "ID_Warehouse",
                    title: "Almacenes",
                    value: "",
                    type: "select",
                    options: [],
                },
                {
                    name: "family",
                    title: "Familia",
                    value: "",
                    type: "select",
                    options: [],
                },
                {
                    name: "category",
                    title: "Categoría",
                    value: "",
                    parent: 'family',
                    type: "select",
                    options: [],
                },
                {
                    name: "subcategory",
                    title: "Subcategoría",
                    value: "",
                    parent: 'category',
                    type: "select",
                    options: [],
                },
                {
                    name: "Lote",
                    title: "Lote",
                    value: "",
                    type: "select",
                    options: [],
                },
            ],
            cacheData: [],
            selectAll: false,
            selectedItem: null,
            adjustmentDate: null,
            adjustmentForm: {},
            dataToAdjust: [],
            showComments: false,
            showDataToAdjust: false,
            showRequests: false,
            warehouses: [],
            entryKey: null,
            statusParams: ['PENDIENTE' , 'ENVIADO' , 'PROCESADO' , 'PROCESADO PARCIAL'],
            showLocations: false,
        };
    },
    computed: {
        selectedItemAdjWarehouseName() {
            if ( this.adjustmentForm.warehouse && !this.selectedItem.WarehouseName ) {
                return this.datafilter.Warehouses.find( current => {
                    const { value: ID_Warehouse } = current;
                    return ID_Warehouse === this.adjustmentForm.warehouse;
                } ).name;
            } else {
                return this.selectedItem.WarehouseName
            } 
        },
        totalStockItemAdjToShow() {
            if (this.selectedItem.TotalStock && this.adjustmentForm.quantity && !this.adjustmentForm.add) {
                return this.selectedItem.TotalStock - this.adjustmentForm.quantity;
            } else if (this.selectedItem.TotalStock && this.adjustmentForm.quantity && this.adjustmentForm.add) {
                return this.selectedItem.TotalStock + +this.adjustmentForm.quantity;
            } else if (this.selectedItem.TotalStock) {
                return this.selectedItem.TotalStock;
            }
            else if((this.selectedItem.TotalStock == null || this.selectedItem.TotalStock == undefined || this.selectedItem.TotalStock == 0 ) && this.adjustmentForm.quantity  ) 
            {
                return this.adjustmentForm.add ? this.adjustmentForm.quantity : -this.adjustmentForm.quantity;
            } 
            return 0;
        },
        getSetLoteAdjust: {
            get () {        
                return this.selectedItem.Lote
            },
            set (val) {
                // alert(val)
                if (val=="") {
                    this.adjustmentForm.lote =  this.selectedItem.Lote
                } else {
                    this.adjustmentForm.lote = val
                }   
            }
        },
        getSetAverageCostAdjust: {
            get () {
                return this.selectedItem.AverageCost
            },
            set (val) {
                this.adjustmentForm.price = val
            }
        },
        getSetWarehouseAdjust: {
            get () {        
                return this.selectedItem.Warehouse
            },
            set (val) {
                // alert(val)
                if (val=="") {
                    this.adjustmentForm.warehouse = this.selectedItem.Lote
                } else {
                    this.adjustmentForm.warehouse = val
                }
            
            }
        },
        stockItemAdjToShow() {
            return this.selectedItem.TotalStock ? this.selectedItem.TotalStock : 0;
        },
    },
    /* watch: {
        selectAll: {
            deep: true,
            immediate: true,
            handler(newValue) {
                console.log(newValue);
                
                if(newValue === true) {
                    for (const item of this.inventoryData) {
                        item.checked = true;
                    }
                    return;
                }
                else {
                    return this.inventoryData.forEach(item => item.checked = false);
                }                
            }
        }
    }, */
    methods: {
        verifyR() {
            if (!this.selectedItem) {
                this.showAlert("warning", "Ajuste de inventario", "Seleccione un producto");
                return;
            }
            this.showDataToAdjust = true;
        },
        closeAlert() {
            this.alert.show = false;

            if (this.alert.options == "reload") {
                this.getEarningDetail();
            }
            else if (this.alert.options == "reloadData") {
                this.reloadData();
            }
        },
        acceptAlert() {
            this.alert.show = false;
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
        reloadData(){
            this.getEarningDetail();
            this.getAdjustmentRequest();
            this.dataToAdjust = [];
        },
        addItemsToRequestAdjustment() {
            for (const item of this.inventoryData) {
                if(item.checked) {
                    const duplicatedItem = this.dataToAdjust.find((el) => el.ID_Item == item.ID_Item && el.PrincipalBarCode == item.PrincipalBarCode && el.Warehouse == item.Warehouse);

                    if(!duplicatedItem) {
                        item.stockAdjustment = '';
                        item.selectLocation = false;
                        this.dataToAdjust.push(item);
                    }
                }
            }
            this.showDataToAdjust = false;        
        },
        handleSelectAll(value) {
            this.selectAll = value;

            if(this.selectAll === true) {
                for (const item of this.inventoryData) {
                    item.checked = true;
                }
                return;
            }
            else {
                for (const item of this.inventoryData) {
                    item.checked = false;
                }
                return;
            }
        },
        handleSelectorItem(e) {
            this.entryKey = e;
            const item = this.inventoryData.find((el) => el.ID_Item == e);
            if(item) {
                item.checked = true;
                item.selectLocation = false;
                item.stockAdjustment = '';
                this.dataToAdjust.push(item);
            }
        },
        handleSelectRequest(data) {            
            this.selectedRequest = data;
            data.AdjustmentRequestDetail.forEach(item => item.isSaved = true);            
            this.dataToAdjust = data.AdjustmentRequestDetail;
            this.showRequests = false;        
        },
        handleDeleteAdjustmentData(item) {
            const newArray = this.dataToAdjust.filter(el => el.ID_Item !== item.ID_Item || el.PrincipalBarCode !== item.PrincipalBarCode || el.Warehouse !== item.Warehouse);            
            
            this.dataToAdjust = newArray;
        },
        async saveAdjustmentRequest(value) {
            if(!this.dataToAdjust.length){
                return this.showAlert("warning", "Advertencia", "No existen items para solicitar ajustes.");
            }

            this.loading = true;

            this.dataToAdjust.forEach(item => {
                item.StockAdjustment = parseFloat(item.StockAdjustment);

                if(isNaN(item.LastPurchasePrice)) {
                    if(item.LastPurchasePrice[0] == '$') {
                        item.LastPurchasePrice = parseFloat(item.LastPurchasePrice.slice(1));
                    }
                    else {
                        item.LastPurchasePrice = parseFloat(item.LastPurchasePrice);
                    }
                }
            });

            if(this.selectedRequest) {
                const dataToInsert = this.dataToAdjust.filter(item => !item.isSaved);
                const dataToUpdate = this.dataToAdjust.filter(item => item.isSaved);

                const adjustmentRequest = {
                    dataToInsert,
                    dataToUpdate,
                    ID_Request: this.selectedRequest.ID_Request,
                    status: value != null && value != this.selectedRequest.Status ? value : null,
                    user: JSON.parse(localStorage.getItem('user')).id,
                }

                await this.$API.inventoryReports.updateRequestInventoryAdjustment(adjustmentRequest)
                    .then((response) => {
                        this.loading = false;
                        this.showAlert("success", "Solicitud de ajuste", response.message, "reloadData");
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false;
                        this.showAlert("warning", "Solicitud de ajuste", "Ocurrio un error al enviar la solicitud.");
                    });
            }
            else {
                const adjustmentRequest = {
                    date: this.adjustmentDate,
                    data: this.dataToAdjust,
                    user: JSON.parse(localStorage.getItem('user')).id,
                    status: value || 0
                }
    
                await this.$API.inventoryReports.requestInventoryAdjustment(adjustmentRequest)
                    .then((response) => {
                        this.loading = false;
                        this.showAlert("success", "Solicitud de ajuste", response.message, "reloadData");
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false;
                        this.showAlert("warning", "Solicitud de ajuste", "Ocurrio un error al enviar la solicitud.");
                    });
            }
        },
        updateCache(data) {
            this.cacheData = data;
        },
        selectionChanged(e) {
            var selectedRows = e.selectedRowsData;
            if (selectedRows.length > 0) {
                this.selectedItem = selectedRows[0];
            }
        },
        getAdjustmentRequest() {
            this.loading = true;
            this.$API.inventoryReports.getAllAdjustmentRequest({ status: 0 , eq: true })
                .then((response) => {
                    this.adjustmentRequests = response.detail;
                    this.selectedRequest = null;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        getEarningDetail() {
            this.loading = true;
            this.$API.inventoryReports
                .getInventoryReport()
                .then((response) => { 
                    this.inventoryData = response;
                    this.inventoryDataList = response.slice(0, 100);
                    this.selectedItem = null;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        onExporting(e) {
            printToExcel(e, "Plantilla de solicitud de ajustes (Informe Físico)");
        },
        filterHandler(response) {
            this.inventoryData = response;
        },
        filterListHandler(response) {
            this.inventoryDataList = response;
        },
        filterRequestsHandler(response) {
            this.adjustmentRequests = response.detail;
        },
        cleanFilter() {
            this.filterParams = {
                ID_Item: null,
                ItemName: null,
                ItemLote: null,
                providersNames: null,
                WarehouseName: null,
            };
            this.getEarningDetail();
        },
        getFilterEarningDetail() {
            this.loading = true;
            this.$API.inventoryReports
                .getInventoryFilterReport(this.filterParams)
                .then((response) => {
                    this.loading = false;
                    this.inventoryData = response;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        handleAdjustmentDate(date) {
            this.adjustmentDate = date;
        },
        handleDeleteRequest() {
            this.loading = true;
            this.$API.inventoryReports.deleteRequestInventoryAdjustment({ ID_Request: this.selectedRequest.ID_Request , ID: this.selectedRequest.ID })
                .then((response) => {
                    this.loading = false;
                    this.showAlert("success", "Solicitud de ajuste", response.message, "reloadData");
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.showAlert("warning", "Solicitud de ajuste", "Ocurrio un error al eliminar la solicitud.");
                });
        },
        assignParams() {
            if (this.datafilter) {
                this.columsConfig[0].options = this.datafilter.Warehouses;
                this.columsConfig[1].options = this.datafilter.Families;
                this.columsConfig[2].options = this.datafilter.Categories;
                this.columsConfig[3].options = this.datafilter.SubCategories;
                this.columsConfig[4].options = this.datafilter.batches;
                this.Reloadfilter += 1;
            }
        },
        handleReset() {
            this.getEarningDetail();
            this.getAdjustmentRequest();
            this.dataToAdjust = [];
        },
        getInventoryLocations(warehouseId) {
            this.loading = true;
            return this.$API.inventoryReports
                .getInventoryLocations(warehouseId)
                .then((response) => response)
                .finally(() => {
                    this.loading = false;
                })
        },
        async onSelectLocationChange(isChecked, { data }) {
            //console.log(data, "onchange")
            if (isChecked) {
                this.previousLocationId = data.location_id;
                const response = await this.getInventoryLocations({ warehouseId: !this.selectedRequest ? data.Warehouse : data.ID_Warehouse });
                data.locations = response;
            } 
        },
        handleSelectLocation(locationId, locationName, data) {
            if (this.previousLocationId !== locationId) {
                data.previousLocationId = this.previousLocationId;
                data.location_id = locationId;
            }

            //data.selectLocation = false
            data.location = locationName;
            this.previousLocationId = null;
        }
       
    },
    mounted() {
        this.assignParams();
        this.getEarningDetail();
        this.getAdjustmentRequest();
    },
};
</script>

<style scoped>
.tabsNH {
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    margin-top: 1rem;
    border-radius: 10px;
}

.btnblack {
    background-color: rgba(60, 60, 60, 1) !important;
    color: white !important;
}

.close-btn {
    color: white !important;
    position: relative !important;
    float: right !important;
}

.border {
  border-radius: 15px !important;
}
</style>
